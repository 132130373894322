import { useSelector } from 'react-redux'
import { Formik, Form, Field } from 'formik'
import { FormattedMessage } from 'react-intl'
import { prop } from 'ramda'
import { useUnmount } from 'react-use'

import WorkspacePreview from 'views/shared/WorkspacePreview'
import validationSchema from 'lib/yupLocalised/schemas/login'
import { isLoggedOutDueToInactivitySelector } from 'state/concepts/login/selectors'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import useDispatchAction from 'hooks/shared/useDispatchAction'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import { employeeLogin } from 'state/concepts/session/actions'
import { setIsLoggedOutDueToInactivity as setIsLoggedOutDueToInactivityAction } from 'state/concepts/login/actions'
import Link from 'views/shared/Link'
import InputField from 'views/shared/InputField'
import CheckboxField from 'views/shared/CheckboxField'
import Alert from 'views/shared/Alert'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import PasswordInputField from 'views/shared/PasswordInputField'
import Recaptcha from 'views/shared/Recaptcha'
import Header from 'views/Login/Header'
import Workspace from './Workspace'

const LoginForm = () => {
  const setIsLoggedOutDueToInactivity = useDispatchAction(setIsLoggedOutDueToInactivityAction)
  const handleSubmit = useFormSubmit(employeeLogin)
  const isLoggedOutDueToInactivity = useSelector(isLoggedOutDueToInactivitySelector)
  const workspace = useSelector(workspaceSelector)

  useUnmount(() => setIsLoggedOutDueToInactivity(false))

  return (
    <div className="login__content mb-40">
      <WorkspacePreview className="mb-32" />
      <Formik
        enableReinitialize
        initialValues={{
          email: '',
          password: '',
          rememberMe: false,
          workspaceId: prop('id', workspace),
        }}
        initialStatus={isLoggedOutDueToInactivity ? { base: { id: 'notifications.automaticLogoutProcessed' } } : {}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ status, isSubmitting }) => (
          <Recaptcha action="workspaceLogin">
            <Form>
              <Header />
              <Workspace workspace={workspace} />
              {prop('base', status) && <Alert className="auth__alert" message={prop('base', status)} />}
              <Field
                name="email"
                component={InputField}
                id="email"
                type="email"
                label={{ id: 'login.email' }}
                icon="alert"
                autoComplete="off"
                data-cy="email-input"
              />
              <Field
                name="password"
                component={PasswordInputField}
                id="password"
                label={{ id: 'login.password' }}
                icon="alert"
                autoComplete="off"
                visiblePassword
                data-cy="password-input"
              />
              <div className="auth__helpers mb-8">
                <Field
                  className="mb-0"
                  name="rememberMe"
                  component={CheckboxField}
                  id="rememberMe"
                  label={{ id: 'login.rememberMe' }}
                  data-cy="remember-me-checkbox"
                />
                <Link href="/reset_password" isRoot>
                  <a className="main-link font-14" data-cy="login-forgot-password-link">
                    <FormattedMessage id="login.forgotPassword" />
                  </a>
                </Link>
              </div>
              <SubmitFormButton
                text={{ id: 'login.logIn' }}
                isDisabled={isSubmitting}
                isFullWidth
                dataCy="submit-log-in"
              />
            </Form>
          </Recaptcha>
        )}
      </Formik>
    </div>
  )
}

export default LoginForm
