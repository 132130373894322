import { Formik, Form, Field } from 'formik'
import { FormattedMessage } from 'react-intl'

import { remindWorkspacesRequestRoute } from 'lib/routes'
import validationSchema from 'lib/yupLocalised/schemas/loginWorkspaceUrl'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import { fetchWorkspace } from 'state/concepts/login/actions'
import Link from 'views/shared/Link'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import WorkspaceUrlInputField from 'views/shared/WorkspaceUrlInputField'
import Header from 'views/Login/Header'
import WorkspacePreview from 'views/shared/WorkspacePreview'

const WorkspaceUrlForm = () => {
  const handleSubmit = useFormSubmit(fetchWorkspace)

  return (
    <div className="login__content mb-40">
      <WorkspacePreview className="mb-32" />
      <Header />
      <p className="text-subheader mb-24">
        <span className="in-blue-gray-800 mr-8">
          <FormattedMessage id="login.DoNotHaveAccount" />
        </span>
        <Link href="/signup" isRoot>
          <a className="main-link" data-cy="sign-up-link">
            <FormattedMessage id="login.signUp" />
          </a>
        </Link>
      </p>
      <Formik initialValues={{ url: '' }} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <Field
              className="mb-24 workspace-url-input-field"
              name="url"
              component={WorkspaceUrlInputField}
              id="url"
              label={{ id: 'login.workspaceUrl' }}
              placeholder={{ id: 'login.workspaceUrlPlaceholder' }}
              icon="alert"
              data-cy="workspace-url-input"
            />
            <SubmitFormButton
              className="mb-24"
              text={{ id: 'login.continue' }}
              isDisabled={isSubmitting}
              isFullWidth
              dataCy="submit-workspace-url"
            />
          </Form>
        )}
      </Formik>
      <div className="text-center">
        <Link href={remindWorkspacesRequestRoute} isRoot>
          <a className="main-link text-subheader font-600" data-cy="login-find-workspace-link">
            <span className="mr-8">
              <FormattedMessage id="login.findWorkspace" />
            </span>
          </a>
        </Link>
      </div>
    </div>
  )
}

export default WorkspaceUrlForm
