import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { CONTACT_US } from 'lib/constants/helpCenterLinks'
import { currentEmployeeSelector } from 'state/concepts/session/selectors'
import isOwner from 'utils/employeePermissions/isOwner'

const AccessProblems = () => {
  const currentEmployee = useSelector(currentEmployeeSelector)

  return (
    <p className="text-center">
      <FormattedMessage id="login.haveProblems" />
      {isOwner(currentEmployee) ? (
        <a href={CONTACT_US} className="ml-6 main-link" target="_blank">
          <FormattedMessage id="login.haveProblems.contactUs" />
        </a>
      ) : (
        <>
          <br />
          <FormattedMessage id="login.haveProblems.contactOwner" />
        </>
      )}
    </p>
  )
}

export default AccessProblems
