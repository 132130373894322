import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  currentEmployeeIdSelector,
  isNeedTwoFactorSelector,
  userProfileTwoFactorSettingSelector,
} from 'state/concepts/session/selectors'
import { subdomainSelector } from 'state/concepts/subdomain/selectors'
import isPresent from 'utils/isPresent'
import SetupTwoFactorSettings from 'views/employee/SetupTwoFactorSettings'
import CleanLayout from 'views/layouts/Clean'
import AuthTermsAndConditionsFooter from 'views/shared/AuthTermsAndConditionsFooter'
import LoginForm from './LoginForm'
import TwoFAForm from './TwoFAForm'
import WorkspaceUrlForm from './WorkspaceUrlForm'

const Login = () => {
  const subdomain = useSelector(subdomainSelector)
  const isNeedTwoFactor = useSelector(isNeedTwoFactorSelector)
  const userProfileTwoFactorSetting = useSelector(userProfileTwoFactorSettingSelector)
  const currentEmployeeId = useSelector(currentEmployeeIdSelector)

  const FormComponent = useMemo(() => {
    if (isNeedTwoFactor && isPresent(userProfileTwoFactorSetting)) {
      return TwoFAForm
    }

    if (currentEmployeeId && !isPresent(userProfileTwoFactorSetting)) {
      return SetupTwoFactorSettings
    }

    return subdomain ? LoginForm : WorkspaceUrlForm
  }, [isNeedTwoFactor, subdomain, userProfileTwoFactorSetting, currentEmployeeId])

  return (
    <>
      <div className="auth__inner subcontainer">
        <FormComponent />
      </div>
      <AuthTermsAndConditionsFooter />
    </>
  )
}

Login.getLayout = page => <CleanLayout>{page}</CleanLayout>

Login.pageTitle = { id: 'pageTitle.login' }

export default Login
