import { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'clsx'
import { getIn } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import { Input } from 'antd'
import { is } from 'ramda'

import InputMessage from '../InputMessage'

const WorkspaceUrlInputField = ({
  id,
  type,
  disabled,
  label,
  icon,
  field,
  placeholder,
  autoComplete,
  className,
  form: { status, errors, touched },
  message,
  messageClass,
  messageTextClass,
  iconColor,
  autoFocus,
  showMessage,
  ...props
}) => {
  const { formatMessage } = useIntl()
  const fieldErrors = getIn(errors, field.name)
  const fieldStatus = getIn(status, field.name)
  const hasErrors = getIn(touched, field.name) && fieldErrors
  const {
    inputMessage,
    inputMessageClass,
    icon: messageIcon,
  } = useMemo(() => {
    if (hasErrors) {
      return { inputMessage: fieldErrors, inputMessageClass: 'main-input--has-message-error', icon }
    }
    if (message) {
      return { inputMessage: message, inputMessageClass: messageClass, icon: 'info' }
    }

    return {}
  }, [fieldErrors, hasErrors, message, messageClass, icon])

  const wrapperClassNames = classNames(
    className,
    'main-input',
    inputMessage && ['main-input--has-message', inputMessageClass],
    {
      'main-input--has-message-alert': fieldStatus,
    },
  )

  return (
    <div className={wrapperClassNames} data-cy="workspace-url-input-wrapper">
      {label && (
        <label className="main-input__label" htmlFor={id}>
          <FormattedMessage {...label} />
        </label>
      )}
      <div className="relative">
        <Input
          addonBefore={<FormattedMessage id="shared.https" />}
          addonAfter={<FormattedMessage id="shared.mainSite" />}
          placeholder={is(Object, placeholder) ? formatMessage(placeholder) : placeholder}
          {...{ type, disabled, id, autoFocus, autoComplete, ...field, ...props }}
        />
      </div>
      {showMessage && inputMessage && (
        <InputMessage
          messageTextClass={messageTextClass}
          message={inputMessage}
          icon={messageIcon}
          iconColor={iconColor}
        />
      )}
    </div>
  )
}

WorkspaceUrlInputField.defaultProps = {
  id: null,
  type: 'text',
  disabled: false,
  autoFocus: false,
  label: null,
  icon: null,
  placeholder: null,
  className: null,
  autoComplete: 'off',
  message: null,
  messageClass: null,
  messageTextClass: null,
  iconColor: null,
  showMessage: true,
}

WorkspaceUrlInputField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.shape().isRequired,
  form: PropTypes.shape({
    touched: PropTypes.shape().isRequired,
    errors: PropTypes.shape().isRequired,
    status: PropTypes.shape(),
  }).isRequired,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.shape(),
  }),
  icon: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  className: PropTypes.string,
  autoComplete: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  messageClass: PropTypes.string,
  messageTextClass: PropTypes.string,
  iconColor: PropTypes.oneOf(['blue', null]),
  showMessage: PropTypes.bool,
}

export default WorkspaceUrlInputField
