import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const LoginWithBackupCodeButton = ({ methodChecker, setIsBackupCodesStep }) => (
  <p className="text-subheader mt-24 mb-0">
    <span className="in-blue-gray-800 mr-8">
      <FormattedMessage id={`login.verificationCode.lostAccess.${methodChecker}`} />
    </span>
    <button className="main-link" onClick={setIsBackupCodesStep} data-cy="backup-code-login">
      <FormattedMessage id="login.withBackupCode" />
    </button>
  </p>
)

LoginWithBackupCodeButton.propTypes = {
  methodChecker: PropTypes.string.isRequired,
  setIsBackupCodesStep: PropTypes.func.isRequired,
}

export default LoginWithBackupCodeButton
