import PropTypes from 'prop-types'
import { prop } from 'ramda'

import { APP_HOST } from 'lib/constants'
import Link from 'views/shared/Link'
import EditPencil from 'views/shared/icons/EditPencil'
import WorkspaceLogo from 'views/shared/WorkspaceLogo'

const Workspace = ({ workspace }) => (
  <div className="main-workspace main-workspace--edit">
    <div className="main-workspace__content">
      <WorkspaceLogo workspace={workspace} dataCy="login-workspace-image" isCurrent />
      <div className="flex-grow-1 ml-12">
        <p data-cy="login-workspace-name" className="text-body font-600 mb-0">
          {prop('name', workspace)}
        </p>
        <p data-cy="login-workspace-url" className="font-12 in-blue-gray-300 mb-0">
          {prop('url', workspace)}
        </p>
      </div>
      <Link href={`${APP_HOST}/login`} isRoot>
        <a rel="noopener noreferrer" data-cy="login-workspace-url-link">
          <EditPencil className="in-blue-600" />
        </a>
      </Link>
    </div>
  </div>
)

Workspace.propTypes = {
  workspace: PropTypes.shape().isRequired,
}

export default Workspace
