import classNames from 'clsx'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from 'lib/constants'

const AuthTermsAndConditionsFooter = ({ className, ...rest }) => (
  <div className={classNames('auth__terms text-subheader', className)} data-cy="auth-terms-section" {...rest}>
    <FormattedMessage
      id="login.agreementFooterText"
      values={{
        termsLink: (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="main-link"
            href={TERMS_OF_USE_LINK}
            data-cy="agreement-terms-and-conditions-link"
          >
            <FormattedMessage id="login.agreementTermsAndConditions" />
            <br className="auth__terms-break" />
          </a>
        ),
        privacyLink: (
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="main-link"
            href={PRIVACY_POLICY_LINK}
            data-cy="agreement-privacy-policy-link"
          >
            <FormattedMessage id="login.agreementPrivacyPolicy" />
          </a>
        ),
      }}
    />
  </div>
)

AuthTermsAndConditionsFooter.defaultProps = {
  className: undefined,
}

AuthTermsAndConditionsFooter.propTypes = {
  className: PropTypes.string,
}

export default AuthTermsAndConditionsFooter
