import { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import useDispatchAction from 'hooks/shared/useDispatchAction'
import { resendOtpCodeOnLogin } from 'state/concepts/session/actions'
import { formatCountdownSeconds } from 'utils/dateTime'
import { MILLISECONDS_PER_SECOND } from 'lib/constants/timeUnits'

const ResendCode = () => {
  const resendCode = useDispatchAction(resendOtpCodeOnLogin)
  const [leftSeconds, setLeftSeconds] = useState(0)

  const onResendCode = () => {
    resendCode()
    setLeftSeconds(60)
  }

  useEffect(() => {
    let timer
    if (leftSeconds > 0) {
      timer = setInterval(() => setLeftSeconds(prevSeconds => prevSeconds - 1), MILLISECONDS_PER_SECOND)
    }

    return () => clearInterval(timer)
  }, [leftSeconds])

  return (
    <p className="text-subheader mt-16 mb-24">
      <span className="in-blue-gray-800 mr-8">
        <FormattedMessage id="login.verificationCode.didNotGetCode" />
      </span>
      {leftSeconds > 0 ? (
        <span className="in-gray-500">
          <FormattedMessage
            id="login.verificationCode.resendAvailableIn"
            values={{
              remainingTime: formatCountdownSeconds(leftSeconds),
            }}
          />
        </span>
      ) : (
        <button className="main-link font-700" onClick={onResendCode} type="button">
          <FormattedMessage id="login.verificationCode.resend" />
        </button>
      )}
    </p>
  )
}

export default ResendCode
