import { Field, Form, Formik } from 'formik'
import { prop, takeLast } from 'ramda'
import { useSelector } from 'react-redux'
import { useToggle } from 'react-use'

import WorkspacePreview from 'views/shared/WorkspacePreview'
import { loginRoute } from 'lib/routes'
import useFormSubmit from 'hooks/shared/form/useFormSubmit'
import { otpCodeSchema } from 'lib/yupLocalised/schemas/userProfileTwoFactorSettings'
import { FormattedMessage } from 'react-intl'
import { employeeSubmitTwoFa } from 'state/concepts/session/actions'
import { currentEmployeeSelector, userProfileTwoFactorSettingSelector } from 'state/concepts/session/selectors'
import Alert from 'views/shared/Alert'
import InputField from 'views/shared/InputField'
import SubmitFormButton from 'views/shared/SubmitFormButton'
import ArrowLeft from 'views/shared/icons/ArrowLeft'
import { TWO_FACTOR_SETTING_METHODS } from 'lib/constants/twoFactorSettings'
import ResendCode from './ResendCode'
import LoginWithBackupCodeButton from './LoginWithBackupCodeButton'
import AccessProblems from './AccessProblems'

const TwoFAForm = () => {
  const handleSubmit = useFormSubmit(employeeSubmitTwoFa)
  const currentEmployee = useSelector(currentEmployeeSelector)
  const { methodChecker, phoneNumber } = useSelector(userProfileTwoFactorSettingSelector) || {}
  const [isBackupCodesStep, setIsBackupCodesStep] = useToggle(false)
  const localeNamespace = isBackupCodesStep ? 'backupLogin' : 'login'
  const handleBack = () => {
    setIsBackupCodesStep(false)
  }

  return (
    <>
      <div className="login__content mb-40">
        <WorkspacePreview className="mb-32" />
        <Formik
          enableReinitialize
          initialValues={{
            otpCode: '',
          }}
          validationSchema={otpCodeSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, isSubmitting }) => (
            <Form>
              <div className="d-flex mb-12">
                <a
                  className="main-link-back main-link-back--blue mr-16"
                  onClick={handleBack}
                  role="button"
                  data-cy="back-button"
                  href={loginRoute}
                >
                  <ArrowLeft size={10} />
                </a>
                <p className="font-700 text-headline mb-0 company-name-break">
                  <FormattedMessage id={`${localeNamespace}.enterCode`} />
                </p>
              </div>
              <p className="text-subheader" data-cy="code-info">
                <FormattedMessage
                  id={isBackupCodesStep ? 'login.backupCodes.description' : `login.methodChecker.${methodChecker}`}
                  values={{
                    email: <span className="font-700">{currentEmployee.email}</span>,
                    last4: <span className="font-700">{takeLast(4, phoneNumber || '')}</span>,
                  }}
                />
              </p>
              {prop('otpCode', errors) && (
                <Alert className="auth__alert" message={{ id: `${localeNamespace}.inValidCode` }} />
              )}
              <Field
                name="otpCode"
                component={InputField}
                id="otpCode"
                type="code"
                label={{ id: `${localeNamespace}.verificationCode` }}
                icon="alert"
                autoComplete="off"
                data-cy="otpCode-input"
                showMessage={false}
              />
              {methodChecker !== TWO_FACTOR_SETTING_METHODS.app && !isBackupCodesStep && <ResendCode />}
              <SubmitFormButton
                text={{ id: 'login.logIn' }}
                isDisabled={isSubmitting}
                isFullWidth
                dataCy="submit-log-in"
              />
              {!isBackupCodesStep && (
                <LoginWithBackupCodeButton methodChecker={methodChecker} setIsBackupCodesStep={setIsBackupCodesStep} />
              )}
            </Form>
          )}
        </Formik>
      </div>
      {isBackupCodesStep && <AccessProblems />}
    </>
  )
}

export default TwoFAForm
